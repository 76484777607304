import './scss/index.scss';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Form, Input, Switch, Button } from 'antd';
import { noop } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { ArrowUpOutlined, CloseOutlined } from '@ant-design/icons';
import { PublishingTabComponent } from './PublishingTab.component';

import {
    LoaderComponent,
    UploadImgComponent,
    TagSelectContainer,
    useGetTags,
    WysiwygComponent,
} from '../../common';
import { QuizGeneratorContainer } from '../../task'
import { TaskButtonDnD } from '../../task/TaskDnD/TaskButtonDnD';
import { TasksListTab } from '../../task/TasksListTab';
import { EditTaskLight } from '../../task';
import { MoveCoursesToWorkspaceContainer } from '../../workspace/MoveCoursesToWorkspace.container'
import { EventListItemComponent } from '../EventListItem.component/EventListItem.component'
import { DripFeedingForm } from './DripFeedingForm';
import { CertificateFormContainer } from '../../certificates';
import { BadgesFormTabComponent } from './BadgesFormTab.component';

const { TextArea } = Input;
const TASKS_LIMIT = 20;

export const CreateEventComponent = (props) => {
    const {
        currencyCode = 'USD',
        onEditEvent = noop,
        inProgress,
        setInProgress = noop,
        eventState,
        setEvent,
        user,
        isOwner,
        taskProp,
        questionPropId,
        tabProp,
        relatedEvents,
        setRelatedEvents,
        workspace,
        ...other
    } = props;

    const isAdmin = user?.role?.id === 1;
    const isNotAllowedAddTask = useMemo(() => (eventState?.tasks?.length || 0) >= TASKS_LIMIT, [eventState?.tasks?.length]);
    const equipment = useRef(eventState?.equipment);
    const description = useRef(eventState?.description || eventState?.message);
    const program = useRef(eventState?.program);
    const history = useHistory();

    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const { getFieldValue, setFieldsValue } = form;
    const tabs = [
        { id: 'overview', title: formatMessage({ id: 'create_event_tab_1' }) },
        { id: 'content', title: formatMessage({ id: 'create_event_tab_2' }) },
        { id: 'dripfeeding', title: formatMessage({ id: 'create_event_tab_4' }) },
        { id: 'certificates', title: formatMessage({ id: 'create_event_tab_5' }) },
        { id: 'badges', title: formatMessage({ id: 'create_event_tab_6' }) },
        { id: 'payments', title: formatMessage({ id: 'create_event_tab_3' }) },
    ].filter(tab => !(eventState.type === "BUNDLE" && (tab.id === 'dripfeeding' || tab.id === 'badges')));

    let f = [];
    eventState?.galleriesInEvent?.forEach((gInE, i) => {
        if (gInE?.gallery?.type === 'PHOTO') {
            f.push({
                url: gInE?.gallery?.source,
                type: gInE?.gallery?.type,
                uid: `gInE_${i}`
            })
        }
    });

    const getTags = useGetTags();
    const [activeTab, setActiveTab] = useState(tabProp ? tabs[tabProp].id : tabs[0].id);
    const [activePaymentTab, setActivePaymentTab] = useState(!!eventState?.stripeProductId ? 1 : 0);
    const [clearStripeData, setClearStripeData] = useState(false);
    const [activeTask, setActiveTask] = useState();
    const [activeid, setActiveid] = useState();
    const [activeQuestionId, setActiveQuestionId] = useState();
    const [imagesList, setImagesList] = useState(f);
    const [stripeProductId, setStripeProductId] = useState(eventState?.stripeProductId);
    const [costLocal, setCostLocal] = useState(eventState?.price?.forStudent);
    const [userCostLocal, setUserCostLocal] = useState(eventState?.price?.forStudent);
    const [showTaskList, setShowTaskList] = useState(false);
    const [exit, setExit] = useState(true);

    const { isSimpleContent, isBundleContent } = useMemo(() => ({
        isSimpleContent: activeTab === 'content' && eventState?.type !== 'BUNDLE',
        isBundleContent: activeTab === 'content' && eventState?.type === 'BUNDLE'
    }), [activeTab, eventState?.type]);

    const [timeTable, setTimeTable] = useState(eventState?.listTimeData?.map((timeData) => ({
        type: timeData.type,
        dateUnix: moment(timeData.date).valueOf()
    })));
    const [isChanged, setIsChanged] = useState();

    const { paymentTab_0, paymentTab_1 } = useMemo(() => (
        {
            paymentTab_0: classnames('form-payment-tab', {
                'form-payment-tab_hidden': activePaymentTab === 1
            }),
            paymentTab_1: classnames('form-payment-tab', {
                'form-payment-tab_hidden': activePaymentTab === 0
            }),
        }
    ), [activePaymentTab]);

    const onFinish = async (data) => {
        let clone = { ...data };
        if (activePaymentTab === 0 && stripeProductId) {
            await setStripeProductId('');
            await setFieldsValue({ stripeProductId: '' });
            await setClearStripeData(true);
            clone.stripeProductId = '';
        }

        let taskIds = eventState?.tasks?.map(s => s.id) || [];
        setInProgress(true);
        const convertedTagIds = await getTags(getFieldValue('tagIds') || eventState?.tagIds);

        const fields = {
            equipment: equipment.current || '',
            description: description.current || '',
            program: program.current || '',
            listTimeData: timeTable ? timeTable.map((t) => ({
                date: new Date(t.dateUnix),
                type: t.type
            })) : [],
            galleriesInEvent: imagesList?.map((e, i) => ({
                order: i,
                gallery: {
                    type: 'PHOTO',
                    source: e.url ?? e?.response?.url
                }
            })),
            cost: costLocal,
            userCost: userCostLocal,
            tagIds: convertedTagIds,
            taskIds: taskIds.map(t => Number(t))
        };

        await onEditEvent(Object.assign({}, clone, fields), exit);
        setInProgress(false);
        setExit(true);
    };

    const setFirstTaskAsActive = () => {
        const firstTask = taskProp || (!!eventState?.tasks && eventState?.tasks[0])
        setActiveTask(firstTask)
        setActiveQuestionId(questionPropId);
        setActiveid(firstTask?.id);
    }

    const handleTab = (key) => {
        setActiveTab(key);
        setActiveTask(null);
        if (key !== 'content') {
            setFirstTaskAsActive();
        }
        setExit(false);
        form.submit();
    };
    const handleTaskView = (task, stepId) => {
        if (!stepId) {
            setActiveTask(task)
            setActiveQuestionId(undefined);
            setActiveid(task?.id)

        } else {
            const findQuestion = task?.questions?.find((question) => question.id == stepId);
            setActiveTask(task);
            setActiveQuestionId(findQuestion?.id);
            setActiveid(findQuestion?.id)
        }
    }
    const onSendSelected = (data, events) => {
        setRelatedEvents(data)
    }

    const formClassNames = classnames('create-event-component__form', {
        'form-payment-tab_hidden': activeTab === 'certificates',
        'form-payment-tab_absolute_d': isSimpleContent
    });

    const getTabClassNames = (index) => classnames('flex-sidebar__tab', {
        'flex-sidebar__tab_active': activeTab === index
    });

    const componentClassNames = classnames('create-event-component start-side-container end-side-container_m', {
        'end-side-container': !isSimpleContent,
        'create-event-component_root': !workspace,
        'create-event-component_root-100vw': !workspace && isSimpleContent
    });
    const initialValues = {
        remember: true,
        isDraft: !!eventState?.isDraft,
        isHideAi: !!eventState?.isHideAi,
        badge: !!eventState?.badge,
        title: eventState?.title || '',
        tagIds: eventState.tags?.map(t => `${t.id}`),
        issue: eventState?.issue || '',
        description: eventState?.description || '',
        equipment: eventState?.equipment || '',
        result: eventState?.result || '',
        program: eventState?.program || '',
        tasks: eventState?.tasks || null,
        isAllowPromoCodes: !!eventState?.isAllowPromoCodes,
        stripeProductId: eventState?.stripeProductId,
        drip: eventState?.drip,
        dripFrequency: eventState?.dripFrequency,
        dripGranularity: eventState?.dripGranularity,
        workspaceIds: eventState?.workspaceIds || [],
        duration: eventState?.duration || null

    }

    useEffect(setFirstTaskAsActive, []);

    return (
        <div className={componentClassNames}>
            {inProgress && <LoaderComponent type='overlay' />}
            <div className="flex_d sidebar-wrapper flex_align-stretch_d min-h-100vh">
                <div className="flex-sidebar">
                    <div className="flex-sidebar__tabs">
                        {tabs.map(tab => (
                            <div key={`flex-sidebar-tab_${tab.id}`}
                                className={getTabClassNames(tab.id)}
                                onClick={() => handleTab(tab.id)}>
                                {tab.title}
                            </div>
                        ))}
                    </div>
                </div>
                {isChanged && <div className="create-event-component__overlay" />}
                <div className="flex-left-column flex_d">
                    {isSimpleContent &&
                        <div className="curriculum_dnd flex-left-body">
                            <TaskButtonDnD
                                {...other}
                                tasks={eventState.tasks}
                                actionHidden={true}
                                showTaskView={handleTaskView}
                                activeId={activeid}
                                setEvent={setEvent}
                                onEditEvent={onEditEvent}
                                event={eventState}
                                setActiveTask={setActiveTask}
                                isNotAllowedAddTask={isNotAllowedAddTask}
                                workspace={workspace}
                            />
                            <div className='curriculum-container'>
                                <QuizGeneratorContainer
                                    event={eventState}
                                    setEvent={setEvent}
                                    inProgress={inProgress}
                                    setInProgress={setInProgress}
                                    type='text'
                                    title={eventState?.title}
                                    curriculum={eventState?.tasks?.map((task, i) => (`${i + 1 + task?.title?.substring(0, 50)}, `)).join(', ')}
                                    tagIds={eventState.tags?.map(t => t.id)}
                                    classes="btn-left block-el "
                                    disabled={isNotAllowedAddTask}
                                    workspace={workspace}
                                    {...other}
                                />
                            </div>
                            {isNotAllowedAddTask &&
                                <div className="wrapper_content-section curriculum-containe error">
                                    {formatMessage({ id: 'limit_warning' })}
                                </div>
                            }
                            <div className='curriculum-container'>
                                <Form.Item>
                                    <Button
                                        onClick={() => setShowTaskList(!showTaskList)}
                                        type="text"
                                        size="small"
                                        block='true'
                                        className="btn-left"
                                    >
                                        {formatMessage({ id: 'add_explainer_short' })}
                                        {showTaskList ? '↑' : "↓"}
                                    </Button>
                                    {showTaskList &&
                                        <TasksListTab
                                            activeTab={formatMessage({ id: 'tab_my_tutorial' })}
                                            author={user}
                                            isMe={true}
                                            viewer={user}
                                            limit={100}
                                            type='simple'
                                            handleTaskSelect={(task) => {
                                                setEvent(p => ({ ...p, tasks: [...p.tasks, task] }));
                                                setShowTaskList(false);
                                            }}
                                        />
                                    }
                                </Form.Item>
                            </div>
                        </div>

                    }
                    {isSimpleContent && (
                        <div className="create-event-component__edit-content transparent-form-fields flex-grow_1 flex_d">
                            {(activeTask || eventState?.tasks?.[0]) &&
                                <EditTaskLight
                                    activeQuestionId={activeQuestionId}
                                    handleTaskView={handleTaskView}
                                    setActiveQuestionId={setActiveQuestionId}
                                    user={user}
                                    activeTask={activeTask || eventState?.tasks?.[0]}
                                    setActiveTask={setActiveTask}
                                    event={eventState}
                                    activeid={activeid}
                                    setEvent={setEvent}
                                    onSaveProgress={setInProgress}
                                    isChanged={isChanged}
                                    setIsChanged={setIsChanged}
                                    isOwner={isOwner}
                                    workspace={workspace}
                                    formEvent={form}
                                    onChangeTab={handleTab}
                                    {...other}
                                />
                            }
                        </div>
                    )}
                    <Form
                        name='create-event'
                        layout='vertical'
                        form={form}
                        initialValues={initialValues}
                        className={formClassNames}
                        onFinish={onFinish}
                    >
                        {activeTab === 'overview' && (
                            <div className="flex-left-body_pb start-side-container_d">
                                <Form.Item
                                    name='title'
                                    rules={[
                                        {
                                            required: true,
                                            message: formatMessage({ id: 'create_event_title_message' }),
                                        },
                                    ]}
                                >
                                    <Input size="large" placeholder={formatMessage({ id: 'create_event_title_label' })} />
                                </Form.Item>


                                <h3>{formatMessage({ id: 'form_workspace_cover' })}</h3>
                                <UploadImgComponent
                                    savedImages={imagesList}
                                    onSetImages={setImagesList}
                                />
                                {eventState?.type !== "BUNDLE" &&
                                    <TagSelectContainer
                                        initialTagsProp={eventState?.tags}
                                        title={eventState?.title}
                                        form={form}
                                        label={formatMessage({ id: 'create_event_subject_label' })}
                                    />
                                }
                                <Form.Item
                                    name='issue'
                                    label={formatMessage({ id: 'course_source_cutoff' })}
                                    rules={[
                                        {
                                            required: false,
                                            message: formatMessage({ id: 'create_event_title_message' }),
                                        },
                                    ]}
                                >
                                    <TextArea autoSize={{
                                        minRows: 2,
                                        maxRows: 16,
                                    }}
                                    // disabled={eventState?.issue}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='result'
                                    label={formatMessage({ id: 'short_description_label' })}
                                    rules={[
                                        {
                                            required: false,
                                            message: formatMessage({ id: 'create_event_title_message' }),
                                        },
                                    ]}
                                >
                                    <TextArea
                                        autoSize={{
                                            minRows: 2,
                                            maxRows: 3,
                                        }}
                                        maxLength={140}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='program'
                                    label={formatMessage({ id: 'learning_objectives_label' })}
                                    rules={[
                                        {
                                            required: false,
                                            message: formatMessage({ id: 'create_event_title_message' }),
                                        },
                                    ]}
                                >

                                    <WysiwygComponent
                                        onChangeContent={(content) =>
                                            (program.current = content)
                                        }
                                        initialState={program.current || ''}
                                        placeholder={formatMessage({
                                            id: 'learning_objectives_label',
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="wysiwyg-field"
                                    name='description'
                                    label={formatMessage({ id: 'overview_title' })}
                                    rules={[
                                        {
                                            required: false,
                                            message: formatMessage({ id: 'create_event_title_message' }),
                                        },
                                    ]}
                                >
                                    <WysiwygComponent
                                        onChangeContent={(content) =>
                                            (description.current = content)
                                        }
                                        initialState={description.current || ''}
                                        placeholder={formatMessage({
                                            id: 'overview_title',
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="wysiwyg-field"
                                    name='equipment'
                                    label={formatMessage({ id: 'prerequisites_and_equipment_label' })}
                                    rules={[
                                        {
                                            required: false,
                                            message: formatMessage({ id: 'create_event_title_message' }),
                                        },
                                    ]}
                                >
                                    <WysiwygComponent
                                        onChangeContent={(content) => (equipment.current = content)}
                                        initialState={equipment.current || ''}
                                        placeholder={formatMessage({
                                            id: 'prerequisites_and_equipment_label',
                                        })}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='duration'
                                    label={formatMessage({ id: 'custom_duration_label' })}
                                    rules={[
                                        {
                                            required: false,
                                            max: 50,
                                            message: formatMessage({ id: 'custom_duration_message' }),
                                        },
                                    ]}
                                >
                                    <Input
                                        maxLength={100}
                                        placeholder={formatMessage({ id: 'custom_duration_placeholder' })}
                                    />
                                </Form.Item>
                            </div>
                        )}

                        {isBundleContent && (
                            <>
                                <div className="events-list_list">
                                    {!!relatedEvents?.length &&
                                        relatedEvents.map((event, i) => (
                                            !!event ? (
                                                <div key={event?.id} className="event-with-checkbox">
                                                    <EventListItemComponent event={event} />
                                                    <Button type="text" icon={<ArrowUpOutlined />} onClick={() => {
                                                        setRelatedEvents((prev) => {
                                                            if (i > 0) { // Ensure there's a previous item to swap with
                                                                const newEvents = [...prev];
                                                                const temp = newEvents[i];
                                                                newEvents[i] = newEvents[i - 1];
                                                                newEvents[i - 1] = temp;
                                                                return newEvents;
                                                            }
                                                            return prev;
                                                        });
                                                    }} />
                                                    <Button type="text" icon={<CloseOutlined />} onClick={() => {
                                                        setRelatedEvents(prev => prev.filter(e => e.id !== event.id));
                                                    }} />
                                                </div>
                                            ) : null
                                        ))}
                                </div>
                                <MoveCoursesToWorkspaceContainer
                                    user={user}
                                    onSendSelected={onSendSelected}
                                    filter={{ type: "ASYNC", workspaceId: workspace?.id, }}
                                    {...other}
                                />
                            </>
                        )}
                        {activeTab === 'dripfeeding' && (
                            <div className="start-side-container_d">
                                <DripFeedingForm formatMessage={formatMessage} form={form} />
                            </div>
                        )}
                        {activeTab === 'badges' && (
                            <div className="wrapper_content flex-left-body start-side-container_d">
                                <BadgesFormTabComponent
                                    formatMessage={formatMessage}
                                    form={form}
                                    initialValuesBadges={initialValues?.badge}
                                    eventState={eventState}
                                />
                            </div>
                        )}
                        {activeTab === 'payments' && (
                            <div className="start-side-container_d">
                                <PublishingTabComponent
                                    isAdmin={isAdmin}
                                    eventState={eventState}
                                    user={user}
                                    currencyCode={currencyCode}
                                    userCostLocal={userCostLocal}
                                    costLocal={costLocal}
                                    setActivePaymentTab={setActivePaymentTab}
                                    activePaymentTab={activePaymentTab}
                                    setStripeProductId={setStripeProductId}
                                    stripeProductId={stripeProductId}
                                    clearStripeData={clearStripeData}
                                    getFieldValue={getFieldValue}
                                    setFieldsValue={setFieldsValue}
                                    setCostLocal={setCostLocal}
                                    setUserCostLocal={setUserCostLocal}
                                    {...other}
                                />
                            </div>
                        )}

                        {!isChanged &&
                            <div className="wrapper_next-step">
                                <div></div>
                                <div className="next-step_btns">
                                    <Button
                                        type='secondary'
                                        onClick={() => history.goBack()}
                                    >
                                        {formatMessage({ id: 'cancel_label' })}
                                    </Button>
                                    <Form.Item>
                                        <Button
                                            type='tertiary'
                                            disabled={inProgress}
                                            htmlType='submit'
                                        >
                                            {formatMessage({ id: 'create_event_save' })}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        }
                    </Form>
                    {activeTab === 'certificates' && (
                        <div className="wrapper_content flex-left-body start-side-container_d">
                            <CertificateFormContainer
                                eventId={eventState?.id}
                                event={eventState}
                            />
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};
