import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { find } from 'lodash';
import { useIntl } from 'react-intl';
import { Loader } from "../../Loader";

import { ProgressBarComponent } from '../ProgressBar.component/ProgressBar.component';
import { SkillsMapComponent } from '../../skillsTree';
import { SubscriptionButtonComponent } from '../../subscription';
import { StartCareerContainer } from '../StartCareer.container';
import { useGetTask } from '../../task/hooks/useGetTask';
import { PROFESSIONS_FLAT } from '../../graph/data/frontend';
import { useIsAllow } from '../../common';

export const ProfessionSkills = ({
    skillsProf,
    spec,
    prof,
    handleSaveUser,
    isParticipant,
    user,
    notClickable,
    taskData,
    isSelected,
    ...other
}) => {

    const useIsAllowd = useIsAllow();
    const isAllow = useIsAllowd("START");

    const GraphName = classnames('wrapper_outer-section', {
        paywall_blur: !isAllow,
    });
    const { getTask } = useGetTask();
    const [description, setDescription] = useState();
    const [counter, setCounter] = useState();
    const { formatMessage } = useIntl();

    const getCounter = () => {
        let clone = { all: 0, done: 0 };
        skillsProf?.forEach((element) => {
            element?.tags?.forEach((element) => {
                clone.all = clone.all + 1;
                clone.done = element.countResults > 0 ? clone.done + 1 : clone.done;
            });
        });
        setCounter(clone);
    };

    useEffect(() => {
        if (skillsProf) {
            getCounter();
        }
    }, [skillsProf]);


    useEffect(() => {
        let profDescription =
            prof?.description ||
            find(PROFESSIONS_FLAT, ['id', parseInt(prof.id)])?.description;

        if (profDescription) {
            (async () => {
                let { data } = await getTask({
                    variables: {
                        taskId: profDescription,
                    },
                });
                setDescription(data?.task.description);
            })();
        } else {
            setDescription(null);
        }
    }, [prof?.id]);

    return (
        <div>
            {!skillsProf && isAllow && <Loader />}

            {skillsProf?.length && (
                <ProgressBarComponent
                    prof={prof}
                    isSelected={isSelected}
                    progressCounter={counter}
                    color={spec?.color || prof.color || '#f6c000'}
                />
            )}
            {skillsProf?.length && (
                <div>
                    {!isAllow ? (
                        <div className='skills_overlay'>
                            <p className='wrapper_inner-section'>
                                {formatMessage({
                                    id: 'career_buy_des',
                                })}
                            </p>

                            <SubscriptionButtonComponent
                                type='primary'
                                block='true'
                            />
                        </div>
                    ) : null
                        // (
                        //     <StartCareerContainer
                        //         prof={prof}
                        //         user={user}
                        //       block="true"
                        //         skillsProf={skillsProf}
                        //         title={
                        //             isSelected
                        //                 ? formatMessage({
                        //                       id: 'career_btn_proceed',
                        //                   })
                        //                 : formatMessage({
                        //                       id: 'career_btn_start_career',
                        //                   })
                        //         }
                        //         size='large'
                        //         counter={counter}
                        //         color={spec?.color || prof.color}
                        //     />
                        // )
                    }
                    <div className={GraphName}>
                        <SkillsMapComponent
                            filteredSubjects={skillsProf}
                            user={user}
                            {...other}
                        />
                    </div>
                    {description && (
                        <div className='ai_bot-answer'>
                            <h2>
                                {formatMessage({
                                    id: 'career_des',
                                })}
                            </h2>

                            <div
                                className='Answer__info'
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
