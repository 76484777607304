import React, { useState, useEffect } from 'react';
import { Form, Switch } from 'antd';
import classnames from 'classnames';
import { AchievementsProgress } from '../../badges';
import { useTaskDripLogic } from '../../task/hooks/useTaskDripLogic';

export const BadgesFormTabComponent = ({
    formatMessage,
    form,
    initialValuesBadges,
    eventState,
    taskState
}) => {
    const { tagsRenew, } = useTaskDripLogic(eventState, taskState, null, null, false, [null]);
    const [showBadges, setShowBadges] = useState(!!initialValuesBadges);

    useEffect(() => {
        const badgeValue = form.getFieldValue('badge');
        setShowBadges(!!badgeValue);
    }, [form.getFieldValue('badge')]);

    const handleBadgeChange = (checked) => {
        form.setFieldsValue({ badge: checked });
        setShowBadges(checked);
    };

    return (
        <>
            <Form.Item
                name="badge"
                label={formatMessage({ id: 'create_event_tab_6' })}
                className="edit-task-component__is-free flex-between"
                valuePropName="checked"
                initialValue={initialValuesBadges}
            >
                <Switch onChange={handleBadgeChange} />
            </Form.Item>
            <p>{formatMessage({ id: 'badges_description' })}</p>

            {!!tagsRenew?.length &&
                <div className={classnames({
                    'cards': true,
                    'select-none': !form.getFieldValue('badge')
                })}>
                    {tagsRenew?.map((tag, i) => (
                        <div className='skills_tag_icon'>
                            <AchievementsProgress
                                type={`small`}
                                key={tag?.id}
                                achievement={{ ...tag, counter: { ...tag?.counter, done: showBadges && tag?.counter?.all } }}
                                width={80}
                            />
                        </div>
                    ))}
                </div>
            }
        </>
    );
};