import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Form, Button, Input, Image } from 'antd';
import { LoaderComponent, UploadImgComponent } from '../../common';
import { CertificateComponent } from '../Certificate.component/Certificate.component';
import { CertificateBadgeComponent } from '../CertificateBadge.component/CertificateBadge.component';

const { TextArea } = Input;

export const CertificateFormComponent = (props) => {
    const {
        certificate,
        loading,
        removeLoading,
        onSend,
        setSertificate,
        onRemove,
        setSelectedImage,
        selectedImage,
        event,
        task
    } = props || {};

    const { id, title, description, label, icon, type = 'BADGE' } = certificate || {};
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { useForm } = Form;
    const [form] = useForm();
    const handleSelectImage = (url) => {
        setSelectedImage(url);
        form.setFieldsValue({ icon: url });
        setSertificate({ ...form.getFieldsValue(), ...(id && { id }) });
    };

    const predefinedImages = Array.from({ length: 14 }, (v, i) => ({
        id: i + 1,
        url: `https://cdn.unschooler.me/web_app/achievement/cert/cert_${i + 1}.png`
    }));

    return (
        <div >
                <div className='certificate-form-component'>
                    <Form
                        name='create-certificate'
                        layout='vertical'
                        form={form}
                        initialValues={
                            {
                                title: title || formatMessage({ id: task ? 'certificate_title_badge' : 'certificate_title' }),
                                description: description || formatMessage({ id: task ? 'certificate_description_badge' : 'certificate_description' }),
                                label: label || formatMessage({ id: 'certificate_label' }),
                                icon: icon || 'https://cdn.unschooler.me/web_app/achievement/cert/cert_1.png'
                            }
                        }
                        onFinish={onSend}
                        onValuesChange={(_, allValues) => {
                            setSertificate({ ...allValues, ...(id && { id }) });
                        }}
                    >
                        <Form.Item name="title"
                            label="Certificate name"
                            rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Certificate description"
                            rules={[{ required: true }]}>
                            <TextArea autoSize={{
                                minRows: 3,
                                maxRows: 4,
                            }}
                                maxLength={300}
                                showCount
                            />
                        </Form.Item>
                        <Form.Item name="label"
                            label="Certificate label"
                            rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name='icon'
                            label="Certificate image"
                            rules={[{ required: true }]}>
                            <div className="image-gallery">
                                {predefinedImages.map((img) => (
                                    <Image
                                        key={img.id}
                                        src={img.url}
                                        width={100}
                                        style={{ margin: '0 8px 8px 0', border: selectedImage === img.url ? '2px solid blue' : 'none' }}
                                        onClick={() => {
                                            handleSelectImage(img.url)
                                        }}
                                        preview={false}
                                    />
                                ))}
                            </div>
                            <UploadImgComponent
                                onSetImages={(data) => {
                                    const { url } = data?.[0]?.response || {};
                                    if (url) {
                                        handleSelectImage(url);
                                    }
                                }}
                                onRemove={() => {
                                    form.setFieldsValue({ icon: '' });
                                    setSelectedImage('');
                                }}
                                limitImages='1'
                                savedImages={selectedImage ? [{ url: selectedImage, thumbUrl: selectedImage }] : []}
                            />
                        </Form.Item>
                        {!!certificate && (
                            task ?
                                <CertificateBadgeComponent
                                    certificate={{
                                        ...(form.getFieldsValue()?.title ? form.getFieldsValue() : certificate),
                                        task: { ...task, creator: event?.creator },
                                        isAppliedForCurrentUser: true,
                                        creator: event?.creator || task?.creator
                                    }}
                                    task={task}
                                />
                                :
                                <CertificateComponent
                                    certificate={{
                                        ...(form.getFieldsValue()?.title ? form.getFieldsValue() : certificate),
                                        event: event,
                                        isAppliedForCurrentUser: true,
                                        creator: event?.creator
                                    }}
                                />
                        )}

                        <div className="flex-container">
                            <Form.Item>
                                <Button type='tertiary'
                                    disabled={loading}
                                    htmlType='submit'>
                                    <span>
                                        {loading && (<LoaderComponent type="inline" />)}
                                        {formatMessage({ id: 'save_certificate' })}
                                    </span>
                                </Button>
                            </Form.Item>
                            {certificate && (
                                <Button type="link"
                                    disabled={removeLoading}
                                    onClick={onRemove}>
                                    <span>
                                        {removeLoading && (<LoaderComponent type="inline" />)}
                                        {formatMessage({ id: 'remove_certificate' })}
                                    </span>
                                </Button>
                            )}
                        </div>
                    </Form>
                </div>

            <div className='wrapper_next-step mt-m-l_m'>
                <div></div>
                <div className='next-step_btns'>
                    <Button
                        type='secondary'
                        onClick={() => history.goBack()}
                    >
                        {formatMessage({ id: 'cancel_label' })}
                    </Button>
                    <Form.Item>
                        <Button
                            type='primary'
                            onClick={() => onSend(form.getFieldsValue(), true)}
                        >
                            {formatMessage({ id: 'create_event_save' })}
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </div>
    )
}
