import {gql} from '@apollo/client';

export const GetTasksByOrder = gql`
    query TasksByOrder($input: TaskOrderInput, $limit: Int, $offset: Int) {
        tasksByOrder(input: $input, limit: $limit, offset: $offset) {
            task {
                id
                creator {
                    id
                    googleUid
                    alias
                    name
                    picture
                    role {
                        description
                        title
                        id
                    }
                }
                isFree
                isLiked
                cover {
                    type
                    source
                    timestamps {
                        timestamp
                        title
                    }
                }
                description
                title
                isLiked
                countLikes
                usersLike {
                    id
                    googleUid
                    name
                    picture
                }
                stateResult
                nextTaskId
                taskInEvents {
                    order
                }
                tags {
                    id
                    title
                    subject {
                        color
                        id
                    }
                }
                questions {
                    id
                }
                isAvailable
            }
            order
        }
    }
`;
