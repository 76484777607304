import { gql } from '@apollo/client';

export const GetTaskSimple = gql`
    query GetTask($taskId: Int, $alias: String) {
        task(id: $taskId, alias: $alias) {
            id
            tags {
                id
                title
                icon
                defIcon
                subject {
                    id
                    title
                    color
                }
            }
            creatorId
            creator {
                id
                googleUid
                name
                picture
            }
            alias
            isModerated
            isDraft
            langId
            title
            coverId
            certificate {
                isAppliedForCurrentUser
                id
                description
                title
                label
                icon
            }
            cover {
                id
                type
                raw
                source
                summary
                timestamps {
                    timestamp
                    title
                    description
                    id
                }
            }
            description
            audioDescription
            isFree
            questions {
                id
                question
                audioQuestion
                explanation
                answer
                options {
                    order
                    text
                    id
                    isCorrect
                }
                order
                type
                title
                cover {
                    id
                    type
                    source
                }
            }
            stateResult
            galleries {
                id
                title
                raw
                summary
                source
                type
                title
                timestamps {
                    title
                    timestamp
                    id
                    description
                }
            }
            taskInEvents {
                eventId
            }
            isAvailable
            isAutogenerated
            summary
            source
        }
    }
`;
