import React, { useEffect, useState, useRef, useMemo } from 'react';
import { cloneDeep } from "lodash";
import { message, Modal } from 'antd';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useGenerateCourse } from '../../Components/ai';

import { useTaskDripLogic } from './hooks/useTaskDripLogic';
import {
    useGetResult,
    useUpdateResult,
    useGetResults,
    useGetResultsByUserid
} from "../result";
import { TaskArticleComponent } from "./TaskArticle.component";
import { TaskCourseArticleComponent } from "./TaskCourseArticle.component";
import { stepParseJson, getModelFromAudioId, useTrackClick, useIsAllow, useConditionalVoiceMode, useProgress } from '../common';
import { useEditTask } from "./hooks/useEditTask";
import { useEditQuestion } from "./hooks/useEditQuestion";
import { useGetResultAi, } from "../ai";
import { useAiChatResponse } from "../ai/graphQL/useAiChatResponse";
import { useIsPermit } from '../roles/hook/useCheckPermission';
import { VideoCustomPlaceholder } from '../common/VideoIframe/VideoCustomPlaceholder';
import { HeaderTutorial } from '../headers';
import { TaskActions } from './TaskActions'
import { useGetUserCertificate } from '../certificates';
let actualQuestions;

export const TaskArticleContainer = (props) => {
    const { task, user, updateTask, event, localePrefix, workspace, isFirst, ...other } = props;
    const { formatMessage } = useIntl();
    const { tags, questions, creatorId } = task || {};
    const { updateResult } = useUpdateResult();
    actualQuestions = questions;

    const questionStarted = useRef(false);
    const { resultsByUserid, getResultsByUserid } = useGetResultsByUserid();
    const trackClick = useTrackClick();
    const history = useHistory();
    const { search } = useLocation();
    let values = queryString.parse(search);
    const { result, getResult } = useGetResult();
    const { results, getResults } = useGetResults();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { inProgress, handleChangeProgress } = useProgress();
    const { editQuestion } = useEditQuestion();

    const [voiceMode, setVoiceMode] = useState();

    const curriculum = useMemo(() => {
        const titles = event?.tasks?.map(t => t?.title?.substring(0, 55)) || [];
        return titles.join(', ');
    }, [event?.tasks]);

    const [highlightedRequest, setHighlightedRequest] = useState();
    const [highlightedNode, setHighlightedNode] = useState();
    const [taskContent, setTaskContent] = useState(task?.description || '');
    const [resultState, setResultState] = useState(result);
    const [inProgressHighlight, setInProgressHighlight] = useState();
    const [taskNotLogged, setTaskNotLogged] = useState(task);
    const [stepId, setStepId] = useState(values?.step);

    const { editTask } = useEditTask();
    const { getResultAi } = useGetResultAi();
    const { getChatResponse } = useAiChatResponse();
    const { getUserCertificate, data: userCertificate } = useGetUserCertificate();


    const { checkPermit } = useIsPermit()
    const editOthersCourses = checkPermit(workspace, 'editOthersCourses')
    const useIsAllowed = useIsAllow();
    const isPaid = useIsAllowed("PREMIUM");
    const userIsAllowGenerateContent = ((isFirst || isPaid || checkPermit(workspace, 'createCourses')) && user?.id === creatorId) || editOthersCourses || user?.role?.id === 1;
    const editFunctions = (user?.id === task?.creator?.id) || (user?.role?.id === 1) || userIsAllowGenerateContent;


    const { taskResult, tagsRenew, progressCounter } = useTaskDripLogic(event, task, resultState, resultsByUserid, userIsAllowGenerateContent, [resultState]);
    const isJoinRequired = useMemo(() => {
        let result = '';
        const currentTaskWithHidden = taskResult?.find(t => t?.id === task?.id);
        const currentQuestion = stepId ? currentTaskWithHidden?.questions?.find(q => q.id === stepId) : null;
        if (user?.id === creatorId || user?.role?.id === 1) {
            return result;
        }

        //check if joined
        if (event && !event?.isDraft) {
            result = !event?.isSubscribe ? 'join' : '';
        }

        if (event && !event?.isDraft) {
            result = !task?.isAvailable && !userIsAllowGenerateContent ? 'join' : '';
        }
        //check if drip feeding
        if (task?.isAvailable && currentTaskWithHidden?.isHidden) {
            result = 'drip';
        }
        //check if it is question is hidden and apply result = 'drip '
        if (task?.isAvailable && currentQuestion?.isHidden) {
            result = 'drip';
        }

        return result;

    }, [user, creatorId, stepId, resultState?.answers?.length, event, task]);
    const { generateCourse } = useGenerateCourse();

    // result
    const onUpdateResultDescription = async (newPartOfDescription) => {
        trackClick(
            'click-resultform',
            task.id,
            `https://unschooler.me${localePrefix}/tutorials/${task.id}`
        );

        let normalisedAnswers = [];
        result?.answers?.forEach((ans) => {
            normalisedAnswers.push({
                answer: ans.answer,
                questionId: ans.questionId,
                optionIds: ans.optionIds
                    ? ans.optionIds
                    : ans.options?.map((option) => option.id),
            });
        });

        if (user?.id) {
            let newResult = {
                answers: normalisedAnswers || [],
                taskId: task.id,
                description: (result?.description ? result?.description : '') + (newPartOfDescription ? newPartOfDescription : '') || '',
            }

            const { id, workspaceId } = event || {};

            await updateResult({
                variables: {
                    resultData: {
                        ...newResult,
                    },
                },
            }, { id, workspaceId });

        }
    };
    const updateResults = async () => {
        getResults({
            variables: { filter: { taskId: task?.id } },
            fetchPolicy: "network-only",
        });

        getResult({
            variables: { taskId: task?.id },
            fetchPolicy: "network-only",
        });
    };
    const handleMouseUp = () => {
        const string = window?.getSelection()?.toString();
        setHighlightedNode(window?.getSelection()?.extentNode?.wholeText);
        setHighlightedRequest(
            window?.getSelection()?.type === "Range" ? string : null
        );
    };
    const onRegenerate = async () => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_description_message' }),
            key: 'descriptionStart'
        })

        try {
            const resp = await generateCourse(event, task?.id, voiceMode?.value);
            successMessage('description');
            await updateTask({ fetchPolicy: "network-only" });
            handleChangeProgress({
                key: 'descriptionStart'
            })

        } catch (err) {
            handleChangeProgress({
                key: 'descriptionStart'
            })
        }
    }
    const handlePlanGenerate = async (settings) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_plan_generate_message' }),
            key: 'planGenerate'
        })
        const resp = await getChatResponse({
            variables: {
                input: settings,
            },
        });
        const responseJSON = resp?.data
            ? JSON.parse(resp?.data?.requestChatApi)
            : null;
        handleChangeProgress({ key: 'planGenerate' })
        if (responseJSON?.choices?.[0]) {
            return responseJSON?.choices?.[0]?.message?.content?.trim();
        }
    };
    const handleCreateQuestion = async (noRedirect) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_create_question_message' }),
            key: 'createQuestion'
        })

        const settings = {
            args: {
                title: task?.title + '\n' + task?.source?.substring(0, 30000),
                curriculum: curriculum,
                goal: event?.title,
            },
            type: "tutorialProjectStepsLongForm",
        };

        let stepTitle = await handlePlanGenerate(settings);

        const parsedSteps = stepParseJson(stepTitle);
        if (parsedSteps?.steps) {
            let convertedQuestions = [];
            let allSteps = parsedSteps.steps;
            for (let step = 0; step < parsedSteps.steps?.length; step++) {
                let questionTitle = `${allSteps[step]}.`;
                let newQuestion = {
                    type: "DEFAULT",
                    question: '',
                    title: questionTitle,
                    order: step,
                };

                convertedQuestions.push(newQuestion);
            }

            actualQuestions = convertedQuestions;
            const { data } = await handleEditQuestions(convertedQuestions);

            handleChangeProgress({ key: 'createQuestion' })
            if (!noRedirect) {
                const nextStepId = data.editTask?.questions?.[0]?.id;
                const searchParams = new URLSearchParams(search);
                searchParams.set('step', nextStepId.toString());
                history.push({ search: searchParams.toString() });
            }
        } else {
            handleChangeProgress({ key: 'createQuestion' })
        }
    };

    const handleCheckResultAi = async (questionId, userAnswer) => {
        handleChangeProgress({
            message: formatMessage({ id: 'progress_check_result_message' }),
            key: 'checkResultAi'
        })

        const question = task?.questions?.find((q) => q.id === questionId);
        const response = await getResultAi(question?.answer, userAnswer, formatMessage);
        if (response) {
            handleChangeProgress({
                key: 'checkResultAi'
            })
            return response;
        }
    };
    const handleEditTask = async (description, aiAnswer) => {
        setTaskContent(description);

        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        if (userIsAllowGenerateContent) {
            try {
                await editTask({
                    variables: {
                        taskId: task?.id,
                        taskData: {
                            description: description,
                            tagIds: tagIdsArray,
                        },
                        isOwner: editOthersCourses,
                    },
                });
                successMessage('description');

            } catch (error) {
                errorMessage('Error editing task, try to reload the page');
            }
        } else if (user?.id) {
            await onUpdateResultDescription(aiAnswer)
        }
        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.description = description;
            return taskLocalNew;
        });
        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };
    const handleEditVideoTask = async (coverData, description) => {
        let tagIdsArray = [];
        task?.tags?.forEach((tag) => tagIdsArray.push(tag.id));

        await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    tagIds: tagIdsArray,
                    description: description,
                    ...coverData,
                },
                isOwner: editOthersCourses,
            },
        });
        successMessage('video');

        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
    };
    const handleEditQuestions = async (convertedQuestions) => {
        let tagIdsArray = [];
        tags?.forEach((tag) => tagIdsArray.push(tag.id));

        const data = await editTask({
            variables: {
                taskId: task?.id,
                taskData: {
                    questions: convertedQuestions,
                    tagIds: tagIdsArray,
                },
                isOwner: editOthersCourses,
            },
        });

        if (updateTask) {
            await updateTask({ fetchPolicy: "network-only" });
        }
        return data;
    };
    const handleReplaceQuestion = async (newQuestion, questionId, aiAnswer) => {
        let arrayCloned = cloneDeep(actualQuestions || task?.questions);
        let question = arrayCloned.find((el) => el.id === questionId);

        Object.keys(newQuestion).forEach((key) => {
            if (key in newQuestion) {
                question[key] = newQuestion[key];
            }
        });

        if (userIsAllowGenerateContent) {
            let { data } = await editQuestion({
                variables: {
                    input: { ...question, }
                },
            });

            if (updateTask) { await updateTask({ fetchPolicy: "network-only" }); }
            successMessage('description');

        } else if (user?.id) {
            await onUpdateResultDescription(aiAnswer)
        }

        actualQuestions = [...arrayCloned];

        setTaskNotLogged((prev) => {
            let taskLocalNew = { ...prev };
            taskLocalNew.questions = [...arrayCloned];
            return taskLocalNew;
        });

    };

    const takeResult = async () => {
        await getResult({
            variables: { taskId: task?.id },
            fetchPolicy: "network-only",
        });
    }

    useEffect(() => {
        setStepId(values?.step)
    }, [values]);

    useEffect(() => {
        setResultState(result);
    }, [result])

    //generate content
    const lessThan20Tasks = !!event && event?.tasks?.length < 21;
    const { getSafetyModel } = useConditionalVoiceMode()

    useEffect(() => {
        if (user?.defaultVoiceId) {
            (async () => {
                const model = await getModelFromAudioId(user.defaultVoiceId);
                if (!model?.model) {
                    setVoiceMode(model);
                    setIsModalVisible(false);

                } else {
                    const safetyModel = await getSafetyModel(user?.id, model);
                    setVoiceMode(safetyModel);
                }

                if (voiceMode) {
                    setIsModalVisible(false);
                }
            })();
        }
    }, [user?.defaultVoiceId])

    useEffect(() => {
        if (!!event && userIsAllowGenerateContent && lessThan20Tasks) {
            if ((!task?.description && !inProgress)) {
                if (!voiceMode && !task?.isAutogenerated) {
                    setIsModalVisible(true);
                } else {
                    setIsModalVisible(false);
                    (async () => {
                        await onRegenerate();
                    })()
                }
            }
        }

    }, [task?.description, event?.id, voiceMode?.value]);

    useEffect(() => {
        setTaskContent(task?.description);
        (async () => await getResults({
            variables: { filter: { taskId: task?.id } },
            fetchPolicy: 'network-only'
        }))();
    }, [task?.id, task?.description]);

    useEffect(() => {
        if (!!user?.id) {
            (async () => await getResultsByUserid({
                variables: { filter: { userId: user?.id } },
                fetchPolicy: 'network-only'
            }))();
            (async () => await takeResult())();
        }
    }, [user?.id]);

    // TODO: check if user is logged in
    useEffect(() => {
        // if (task?.certificate?.isAppliedForCurrentUser && user?.id) {
        if ( user?.id) {
            (async () => {
                await getUserCertificate({
                    variables: {
                        certificateId: task?.certificate?.id,
                        userId: user?.id
                    }
                });
    
            })()
        }
    }, [task?.certificate, result]);

    const successMessage = (content) => {
        message.success(formatMessage({ id: 'content_saved' }, { content }), 3);
    };
    const errorMessage = () => {
        message.error('Ai did not respond, reload the page', 3);
    };

    return <>
        <div className='tasks-page '>
            {editFunctions &&
                <HeaderTutorial task={task}
                    type={"small"}
                    event={event}
                    customComponent={TaskActions}
                    user={user}
                    {...other} />}

            {(!!task?.questions?.length || !!task?.taskInEvents?.length) ? (
                <TaskCourseArticleComponent
                    {...other}
                    task={userIsAllowGenerateContent ? task : taskNotLogged}
                    event={event}
                    results={results}
                    result={resultState}
                    updateResults={updateResults}
                    setResult={setResultState}
                    user={user}
                    taskContent={taskContent}

                    handleEditTask={handleEditTask}
                    handleReplaceQuestion={handleReplaceQuestion}
                    handleEditVideoTask={handleEditVideoTask}
                    handleCheckResultAi={handleCheckResultAi}

                    highlightedRequest={highlightedRequest}
                    handleMouseUp={handleMouseUp}
                    setInProgressHighlight={setInProgressHighlight}
                    setHighlightedNode={setHighlightedNode}
                    setHighlightedRequest={setHighlightedRequest}
                    highlightedNode={highlightedNode}
                    inProgressHighlight={inProgressHighlight}

                    handlePlanGenerate={handlePlanGenerate}
                    updateTask={updateTask}
                    setInProgress={handleChangeProgress}
                    inProgress={inProgress}

                    stepId={stepId}
                    setStepId={setStepId}
                    onUpdateResultDescription={onUpdateResultDescription}
                    workspace={workspace}
                    questionStarted={questionStarted}
                    isJoinRequired={isJoinRequired}
                    taskResult={taskResult}
                    tagsRenew={tagsRenew}
                    progressCounter={progressCounter}
                    userIsAllowGenerateContent={userIsAllowGenerateContent}
                    canEdit={editFunctions}
                    userCertificate={userCertificate?.getUserCertificate}
                />
            ) : (
                <TaskArticleComponent
                    {...other}
                    task={userIsAllowGenerateContent ? task : taskNotLogged}
                    userIsAllow={userIsAllowGenerateContent}
                    result={resultState}
                    updateResults={updateResults}
                    user={user}
                    highlightedRequest={highlightedRequest}
                    handleMouseUp={handleMouseUp}
                    handleEditTask={handleEditTask}
                    handleEditVideoTask={handleEditVideoTask}
                    handleCheckResultAi={handleCheckResultAi}
                    updateTask={updateTask}
                    handleReplaceQuestion={handleReplaceQuestion}
                    setInProgressHighlight={setInProgressHighlight}
                    inProgress={inProgress}
                    inProgressHighlight={inProgressHighlight}
                    stepId={stepId}
                    setStepId={setStepId}
                    workspace={workspace}
                    taskResult={taskResult}
                    tagsRenew={tagsRenew}
            
                />
            )}
        </div>
        <Modal
            visible={isModalVisible}
            footer={null}
            closable={true}
            header={null}
            onCancel={() => setIsModalVisible(false)}
            width={700}
        >
            <VideoCustomPlaceholder
                userIsAllowGenerateContent={userIsAllowGenerateContent}
                event={event}
                customTitle='Select default Voice and AI avatar for videos'
                handleRequest={() => {
                    onRegenerate()
                    setIsModalVisible(false)
                }}
                buttonLabel='module_btn'
                setVoiceMode={(selectedVoice) => {
                    setVoiceMode(selectedVoice);
                    setIsModalVisible(false)
                }}
                voiceMode={voiceMode}
                defaultVoice={user?.defaultVoiceId}
                user={user}

            />

        </Modal>
    </>
};