import {gql} from '@apollo/client';

export const GetTasks = gql`
    query GetTasks($page: Int, $filter: FilterTasks) {
        tasks(page: $page, filter: $filter) {
            id
            isFree
            isLiked
            description
            title
            stateResult
            isAutogenerated
            creator {
                id
                alias
                name
                picture
            }
            cover {
                type
                source
            }
            taskInEvents {
                order
            }
            tags {
                id
                title
                subject {
                    color
                    id
                }
            }
            questions {
                id
                question
                explanation
                answer
                options {
                    order
                    text
                    id
                    isCorrect
                }
                order
                type
                title
                cover {
                    id
                    type
                    source
                }
            }
            workspaceId
        }
    }
`;
