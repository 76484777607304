import {gql} from "@apollo/client";

export const SEARCH_EVENTS = gql`
    query SearchEvents($input: SearchEventsInput!) {
        searchEvents(input: $input) {
            id
            alias
            type
            title
            galleriesInEvent {
                gallery {
                    id
                    type
                    source
                }
                order
            }
            creator {
                id
                alias
                name
                picture
            }
            subjects {
                title
                color
                id
            }
            tasks {
                stateResult
                id
                difficultyId
            }
            tags {
                id
                title
                subject {
                    color
                    id
                }
            }
            isSubscribe
            isModerated
            isDraft
            workspaceId
        }
    }
`;