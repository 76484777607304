import './scss/index.scss';
import _ from 'lodash';
import React from "react";
import { Svg } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { SPEC_SUBJECTSID } from '../../constant';

// library.add(fas);
library.add(fad);

export const ProgramsSubjectIconComponent = (props) => {
    const {
        width = 30,
        achievement,
        hideIcon,
        colorfull
    } = props;
    const color = colorfull ? (achievement?.color || achievement?.subject?.color) : 'var(--color-primary)';
    const specIndex = _.findIndex(SPEC_SUBJECTSID, function (spec) { return spec.subjectIds.includes(achievement.subject?.id) });
    const icon = SPEC_SUBJECTSID[specIndex]?.icon || ":icon/achievement";
    return (
        <div className="program-subject__item " style={{ width: width }}>
            {!hideIcon && (
                <FontAwesomeIcon
                    className={`program-subject__item_icon ${colorfull ? 'program-subject__item_icon_active' : ''}`}
                    icon={['fad', achievement?.defIcon || 'graduation-cap']}

                />
            )}
            {/* <Svg name={`${icon}`} className="achievement-svg-icon" */}
            <Svg name={`:icon/achievement`} className="achievement-svg-icon"
                fill={color}
                width={width}
            />
        </div>
    );
};
