import {gql} from "@apollo/client";

export const EditTask = gql`
    mutation EditTask($taskData: EditTaskInput!, $taskId: Int!, $isOwner: Boolean) {
        editTask(taskData: $taskData, taskId: $taskId, isOwner: $isOwner) {
            id
            isDraft
            creatorId
            alias
            createDate
            langId
            title
            coverId
            duration
            cover {
                id
                type
                source
                timestamps {
                    id
                    title
                    description
                    timestamp
                }
                summary
                raw
                title
            }
            galleries {
                id
                type
                source
                timestamps {
                    id
                    title
                    description
                    timestamp
                }
                summary
                raw
                title
            }
            description
            audioDescription
            isFree
            difficultyId
            questions {
                id
                title
                question
                explanation
                answer
                options {
                    id
                    text
                    isCorrect
                    order
                }
                cover {
                    id
                    type
                    source
                }
                order
                type
            }
            isAutogenerated
            workspaceId
            countLikes
            tags {
                id
                alias
                subject {
                    color
                    id
                }
                title
                icon
                defIcon
                countResults
            }
            isLiked
            stateResult
            isAvailable
            source
            summary
        }
    }
`;