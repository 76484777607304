import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash';
import { CertificateFormComponent } from '../CertificateForm.component';
import { LoaderComponent } from '../../common';
import { useGetCertificate, useCreateCertificate, useEditCertificate, useRemoveCertificate } from '../hook';
import { GetCertificateSimple } from '../graphQL/queries/GetCertificateSimple'
import { FirebaseContext } from '../../../context/firebase/firebaseContext';

let CERT = null;
export const CertificateFormContainer = (props) => {
    const { id, eventId, event, task, handleReloadTask = noop } = props || {};
    const history = useHistory();
    const { user } = useContext(FirebaseContext);
    const { getCertificate, loading: getLoading } = useGetCertificate(GetCertificateSimple);
    const { createCertificate, loading: createLoading } = useCreateCertificate();
    const { editCertificate, loading: editLoading } = useEditCertificate();
    const { removeCertificate, loading: removeLoading } = useRemoveCertificate();

    const [isLoading, setIsloading] = useState();
    const [certificate, setCertificate] = useState();
    const [selectedImage, setSelectedImage] = useState(certificate?.icon || '');
    const taskId = task?.id || null;

    const handleSend = async (data, isExit) => {
        const { id: creatorId } = user || {};
        if (CERT) {
            CERT = null;

            if (!!certificate?.id || data?.id) {
                const { id, ...certData } = data || {};
                const resp = await editCertificate({
                    variables: {
                        input: { 
                            ...certData, 
                            eventId: eventId || null, 
                            taskId: taskId || null, 
                            creatorId, 
                            type: taskId  ? 'BADGE' : 'CERT' 
                        },
                        id: certificate?.id || data?.id
                    }
                });
                await setCertificate(resp?.data?.editCertificate);
            } else {
                const resp = await createCertificate({
                    variables: {
                        input: {
                            ...data,
                            type: taskId  ? 'BADGE' : 'CERT',
                            eventId: eventId || null,
                            taskId: taskId || null,
                            creatorId
                        }
                    }
                });
                await setCertificate(resp?.data?.createCertificate);
            }
            if (handleReloadTask) {
                handleReloadTask();
            }
        }

        if (isExit) {
            history.goBack()
        }
    }

    const getData = async () => {
        CERT = null;
        await setIsloading(true);
        const resp = await getCertificate({
            variables: {
                ...(id && { id }),
                ...(eventId && { eventId }),
                ...(taskId && { taskId })
            },
            fetchPolicy: 'network-only'
        });

        await setCertificate(resp?.data?.getCertificate);
        await setIsloading(false);
    };

    const handleRemove = async () => {
        const resp = await removeCertificate({
            variables: {
                id: certificate.id
            }
        });
        await setCertificate(null);
        await getData();
        if (handleReloadTask) {
            handleReloadTask();
        }
    };

    const onSetCertificate = async (data) => {
        CERT = data;
        await setCertificate(data);
    }

    useEffect(() => {
        if (eventId || taskId || id) {
            (async () => {
                await getData();
            })()
        }
    }, [id, eventId, taskId]);

    useEffect(() => {
        return () => {
            if (CERT) {
                (async () => {
                    await handleSend(CERT);
                })()
            }
        };
    }, [])

    if (isLoading) {
        return <LoaderComponent />
    }

    return (
        <CertificateFormComponent
            certificate={certificate}
            loading={getLoading || createLoading || editLoading}
            removeLoading={removeLoading}
            onRemove={handleRemove}
            onSend={handleSend}
            setSertificate={onSetCertificate}
            setSelectedImage={setSelectedImage}
            selectedImage={selectedImage}
            event={event}
            task={task}
        />
    )
}