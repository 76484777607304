import { gql } from "@apollo/client";

export const GetEventFull = gql`
    query GetEvent($id: Int, $alias: String, $currencyId: Int, $workspaceId: Int, $isSuperUser: Boolean) {
        event(id: $id, alias: $alias, workspaceId: $workspaceId, isSuperUser: $isSuperUser) {
            id
            type
            alias
            isDraft
            title
            description
            program
            result
            equipment
            isHideAi
            isAllowPromoCodes
            drip
            dripFrequency
            dripGranularity
            langId
            duration
            badge
            certificate {
                description
                icon
                id
                label
                title
            }
            tags {
                id
                title
                subject {
                    color
                    id
                }
                icon
                defIcon
            }
            issue
            createDate
            room {
                type
                link
            }
            workspaceId
            workspaceIds
            relatedEvents {
                event {
                    id
                    title
                    isSubscribe
                    duration
                    tasks {
                        title
                        id
                        isAvailable
                        isFree
                        duration
                    }
                    galleriesInEvent {
                        gallery {
                            type
                            source
                        }
                    }
                    price {
                        forStudent
                    }
                    priceSubscription {
                        forStudent
                    }
                }
                order
            }
            listTimeData {
                type
                date
            }
            price(currencyId: $currencyId) {
                currency {
                    id
                    title
                    symbol
                    code
                }
                forStudent
                forTeacher
                total
                forStudentUSD
            }
            priceSubscription(currencyId: $currencyId) {
                currency {
                    id
                    title
                    symbol
                    code
                }
                forStudent
                forTeacher
                total
                forStudentUSD
            }
            creator {
                id
                name
                picture
                canTeach
            }
            galleriesInEvent {
                gallery {
                    type
                    source
                }
                order
            }
            subjects {
                id
                title
                color
            }
            studentsLimit
            countUserSubscriptions
            countAllUserSubscriptions
            userSubscriptions {
                id
                role
                studentId
                invitedEmail
                student {
                    id
                    email
                    alias
                    name
                    picture
                    results {
                        state
                        taskId
                        description
                        answers {
                            answer
                            questionId
                            createDate
                            options {
                                id 
                                isCorrect
                                text
                            }
                        }
                        modifyDate
                        createDate
                    }
                    lastVisit
                }
                start
                end
                isCanceled
                stripeSubscriptionId
            }
            isSubscribe
            tasks {
                id
                creatorId
                alias
                duration
                cover {
                    id
                    type
                    source
                    raw
                    summary
                    title
                    timestamps {
                        timestamp
                        title
                        description
                        id
                    }
                }
                certificate {
                    isAppliedForCurrentUser
                    id
                    description
                    title
                    label
                    icon
                }
                description
                audioDescription
                title
                difficulty {
                    title
                    id
                }
                isAvailable
                isAutogenerated
                isFree
                isLiked
                isDraft
                stateResult
                tags {
                    id
                    title
                    subject {
                        color
                        id
                    }
                    defIcon
                    icon
                }
                questions {
                    id
                    order
                    title
                    type
                    explanation
                    question
                    audioQuestion
                    options {
                        text
                        order
                        isCorrect
                        id
                    }   
                    answer
                    cover {
                        source
                        type
                        id
                    }
                }
                source
                summary
                galleries {
                    id
                    type
                    source
                    title
                }
            }
            stripeProductId
            stripeProduct {
                id
                prices {
                    priceId
                    price
                    currency
                    type
                    recurringPeriod
                }
            }
        }
    }
`;