export function calculateTaskProgress(task, event, result) {
    const progressColor =
        event?.subjects?.[0]?.color ||
        task?.tags?.[0]?.subject?.color ||
        '';

    const progressPercentage = result?.answers?.length ? (result.answers.length / task?.questions?.length) * 100 : 0;
    const done = result?.answers?.length || 0;
    const all = task?.questions?.length || 0;
    const correct = task?.questions?.filter(question => question.stateResult === 'CORRECT').length || 0;    // console.log(correct,  task?.questions,'correct, all');
    // const backgroundColor = event?.subjects?.[0]?.color + 30 || task?.tags?.[0]?.subject?.color + 30 || '';

    return {
        progressColor,
        progressPercentage,
        done,
        all,
        correct,
    };
}