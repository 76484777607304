import { gql } from '@apollo/client';

export const EditCertificate = gql`
    mutation EditCertificate($input: CreateCertificateInput, $id: Int!) {
        editCertificate(input: $input, id: $id) {
            id
            title
            description
            label
            icon
            type
            creatorId
            eventId
            taskId
            event {
                id
            }
            users {
                id
                userName
            }
        }
    }
`;