import React, { useContext, useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { FirebaseContext } from "../../../context/firebase/firebaseContext";
import { LoginContainer, useIsAllow } from "../../common";
import { useGetUserWithEvents } from '../../common/graphQL';
import { TasksListTab } from '../../task/TasksListTab';
import { ListBundlesContainer } from "../../bundles"
import { AIAllFeaturesContainer } from "../../ai";
import { TodoListContainer } from '../../profile/TodoList.container/TodoList.container';
import { ReportComponent } from '../../profile/Report.component/ReportComponent';
import { useGetResultsByUserid } from "../../result";

import { PaginatedListEventsContainer, SimpleEventsListContainer, EventsListComponent } from '../../events';

export const EventsContainer = (props) => {
    const { workspace, ...other } = props;
    const { formatMessage } = useIntl();
    const { user } = useContext(FirebaseContext);
    const { getUser, loading, user: userEvents } = useGetUserWithEvents();
    const { resultsByUserid, getResultsByUserid } = useGetResultsByUserid();

    const [showLogin, setShowLogin] = useState();
    const [activeTab, setActiveTab] = useState(formatMessage({ id: "tab_ai_course" }));

    const useIsAllowed = useIsAllow();
    const createCourses = useIsAllowed("PREMIUM", workspace);
    const showTask = (activeTab === (formatMessage({ id: "tab_ai_mentor" })) || (activeTab === formatMessage({ id: 'tab_ai_test' })));
    const waitProfile = async () => { await getUser(user?.id, null, null, 50, null, "SUBSCRIBED"); };
    useEffect(() => {
        if (user?.id) {
            (async () => await waitProfile())();
            (async () => await getResultsByUserid({
                variables: { filter: { userId: user?.id } },
                fetchPolicy: 'network-only'
            }))();
        }
    }, [user?.id]);


    return (
        <>
            <div className='flex-container page'>
                <div className='flex-left-column'>
                    <AIAllFeaturesContainer user={user} sayMeTab={setActiveTab} />
                </div>
                {/* {(user?.id && !!userEvents?.events?.length) &&
                    <div className='flex-right-column flex-right-column_profile'>
                        <TodoListContainer user={userEvents} />

                        <ReportComponent
                            id={user?.id}
                            itsMe={true}
                            user={user}
                            updatedData={updatedData}
                            handleChange={handleChange}
                            handleChangeSchedule={handleChangeSchedule}
                            {...other}
                        />
                    </div>
                } */}
            </div>

            <div className="page">
                <div className="">
                    {(showTask && user?.id) ?
                        <>
                            <h2>{formatMessage({ id: 'last_questions' })}:</h2>
                            <TasksListTab
                                activeTab={formatMessage({ id: 'tab_my_tutorial' })}
                                author={user}
                                tabs={[]}
                                isMe={true}
                                viewer={user}
                                limit={10}
                            />
                        </>
                        :
                        <>
                            {user?.id ?
                                <>
                                    {/* show courses I created in teacher view: list with students  */}
                                    <PaginatedListEventsContainer
                                        {...other}
                                        title={formatMessage({ id: "tab_events_my" })}
                                        filterData={{creatorId: user?.id,}}
                                        limit={20}
                                        user={user}
                                        typeProp="list"
                                        createCourses={createCourses}
                                        isAllowSearch={true}
                                    />

                                    {/* list of bundles */}
                                    {createCourses && <div className="wrapper_outer-section">
                                        <ListBundlesContainer
                                            {...other}
                                            user={user}
                                            filterData={{
                                                creatorId: user?.id,
                                            }}
                                            title='Course bundles'
                                            activeTab={formatMessage({ id: "tab_events_user" })}
                                        />
                                    </div>}

                                    {!!userEvents?.events?.length ?
                                        // courses I'm learning
                                        <EventsListComponent
                                            {...other}
                                            isLoading={loading}
                                            events={userEvents?.events}
                                            user={userEvents}
                                            title={formatMessage({ id: "tab_events_joined" })}
                                            createCourses={createCourses}
                                            resultsByUserid={resultsByUserid}
                                        />
                                        :
                                        // if there is no courses show guides
                                        <SimpleEventsListContainer eventsList={[30917]} openFirtsLesson={true} />
                                    }
                                </>
                                :
                                <div className="wrapper_outer-section">
                                    <PaginatedListEventsContainer
                                        {...other}
                                        title={formatMessage({ id: "group_soon" })}
                                        filterData={{
                                            subjectIds: [245],
                                            isDraft: false
                                        }}
                                        user={user}
                                        viewer={user}
                                        typeProp="card"
                                    />

                                </div>
                            }
                        </>
                    }
                </div>
            </div>

            <LoginContainer
                showLogin={showLogin}
                setCancelLogin={setShowLogin}
            />
        </>
    );
};
