import {gql} from "@apollo/client";

export const GetEventSimple = gql`
    query GetEvent($id: Int, $alias: String, $currencyId: Int) {
        event(id: $id, alias: $alias) {
            id
            alias
            title
            tags {
                id
                title
                subject {
                    color
                    id
                }
            }
            langId
            price(currencyId: $currencyId) {
                forStudent
            }
            creator {
                id
                name
                picture
            }
            galleriesInEvent {
                gallery {
                    type
                    source
                }
                order
            }
            countUserSubscriptions
            workspaceId
            workspaceIds
        }
    }
`;
