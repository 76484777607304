import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useContext } from 'react';

import { QuestionsFormContainer, Thread, TaskPlaceholderComponent } from '../task';
import { useQueryParams } from '../common';
import { FirebaseContext } from '../../context/firebase/firebaseContext';
import { calculateTaskProgress } from '../common/utils/calculateTaskProgress';
import { AchievementPreviewComponent } from '../badges/';
import { CertificateBadgeComponent } from '../certificates/CertificateBadge.component/CertificateBadge.component';
import { Modal } from 'antd';

export const TaskCourseArticleComponent = (props) => {
    const {
        task,
        user,
        event,
        handleSayMeAnswers,
        result,
        highlightedRequest,
        isJoinRequired,
        userIsAllowGenerateContent,
        userCertificate,
        ...other
    } = props;
    const { isSingleMode, isSingleLesson } = useQueryParams();
    const { formatMessage } = useIntl();
    const { localePrefix } = useContext(FirebaseContext);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [menuOpen, setMenuOpen] = useState(() => {
        const storedMenuOpen = window.localStorage.getItem('menuOpen');
        return storedMenuOpen !== null ? JSON.parse(storedMenuOpen) : true;
    });

    const handleMenuOpen = () => {
        setMenuOpen(prev => {
            const newState = !prev;
            window.localStorage.setItem('menuOpen', JSON.stringify(newState));
            return newState;
        });
    };

    const showNotificationTag = (tags, triggerMessage, triggerDescription, cloneResult, share, userCertificateProp) => {
        let progress = calculateTaskProgress(task, event, cloneResult);
        let certUser = userCertificateProp || userCertificate
        let certificate = null;

        console.log(userCertificate, userCertificateProp, task?.certificate, 'userCertificate')
        if (certUser) {
            certificate = {
                ...task?.certificate,
                active: true,
                isAppliedForCurrentUser: true,
                userCertificateId: certUser?.id,
                student: {
                    id: certUser?.userId,
                    name: certUser?.userName,
                    picture: certUser?.user?.picture
                },
                createdDate: certUser?.createdDate,
                creator: task?.creator
            }
        } else {
            certificate = { ...task?.certificate, creator: task?.creator };
        }

        setModalContent(
            task?.certificate ? (
                <CertificateBadgeComponent
                    certificate={certificate}
                    task={task}
                    taskResult={result}
                />
            ) : (
                <AchievementPreviewComponent
                    achievements={tags.map(tag => ({
                        ...tag,
                        counter: {
                            all: progress.all,
                            done: progress.done
                        }
                    }))}
                    share={share}
                    triggerMessage={triggerMessage}
                    triggerDescription={triggerDescription}
                    onClose={() => setIsModalOpen(false)}
                    formatMessage={formatMessage}
                    localePrefix={localePrefix}
                />
            )
        );

        setIsModalOpen(true);
    };

    return (
        <div className="task-article-component">
            <Modal
                visible={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                centered
                width="auto"
                style={{ maxWidth: '1000px' }}
                maskClosable={true}
                closable={true}
            >
                {modalContent}
            </Modal>
            {(menuOpen && !isSingleMode && !isSingleLesson) &&
                <Thread
                    event={event}
                    task={task}
                    taskId={task?.id}
                    result={result}
                    user={user}
                    showNotificationTag={showNotificationTag}
                    handleMenuOpen={handleMenuOpen}
                    {...other}
                />
            }
            <div className='task-article-content'>
                {isJoinRequired && (
                    <TaskPlaceholderComponent
                        task={task}
                        event={event}
                        user={user}
                        type={isJoinRequired}
                        {...other}
                    />
                )}

                {!isJoinRequired && (
                    <section className="article">
                        <QuestionsFormContainer
                            {...other}
                            id={task?.id}
                            task={task}
                            user={user}
                            event={event}
                            sayMeAnswers={handleSayMeAnswers}
                            result={result}
                            highlightedRequest={highlightedRequest}
                            singleMode={isSingleMode}
                            singleLesson={isSingleLesson}
                            userIsAllowGenerateContent={userIsAllowGenerateContent}
                            setMenuOpen={handleMenuOpen}
                            menuOpen={menuOpen}
                            showNotificationTag={showNotificationTag}
                        />
                    </section>
                )}
            </div>
        </div>
    );
}
