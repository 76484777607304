import { gql, useLazyQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

export function useGetUserWithEvents(userId) {
    const [queryUser, { data, loading, error }] = useLazyQuery(GET_USER, {
        fetchPolicy: 'network-only',
    });

    const getUser = useCallback(
        async (id, alias, googleUid, limit = 10, offset = 0, filter) => {
            const result = await queryUser({
                variables: {
                    userId: id || userId,
                    alias: alias,
                    googleUid: googleUid,
                    limit,
                    offset,
                    filter,
                },
            });
            return result.data?.user;
        },
        [queryUser, userId]
    );

    return useMemo(
        () => ({
            user: data?.user,
            loading,
            error,
            getUser,
        }),
        [data?.user, loading, error, getUser]
    );
}

const GET_USER = gql`
    query GetUser($userId: Int, $alias: String, $googleUid: String, $limit: Int, $offset: Int, $filter: UserEventsFilter) {
        user(id: $userId, alias: $alias, googleUid: $googleUid) {
            id
            alias
            createDate
            email
            phone
            name
            description
            picture
            role {
                id
                title
                description
            }
            subscriptionUser {
                type
                startDate
                endDate
                isCanceled
            }
            events (limit: $limit, offset: $offset, filter: $filter) {
                id
                alias
                type
                title
                isDraft
                galleriesInEvent {
                    gallery {
                        id
                        type
                        source
                    }
                    order
                }
                creator {
                    id
                    name
                    picture
                }
                tags {
                    id
                    title
                    subject {
                        color
                        id
                    }
                }
                tasks {
                    title
                    stateResult
                    id
                    questions{
                        id
                        type
                    }
                }
                price {
                    currency {
                        id
                        code
                    }
                    forStudent
                }
                priceSubscription {
                    currency {
                        id
                        code
                    }
                    forStudent
                }
                isSubscribe
                studentsLimit
                nextDate
                workspaceId
            }
            hidden
            canTeach
            lang {
                id
                title
            }
        }
    }
`;
