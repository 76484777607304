import './scss/index.scss';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faBars } from '@fortawesome/free-solid-svg-icons';

import { QuestionFormContext } from '../../../context/question/QuestionFormContext';
import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { QuestionComponent } from '../Question.component';
import { TaskProgressContainer } from '../';
import { ResultListContainer, FreeResultFormContainer } from '../../result';
import { AddNewContentContainer, AiAssistantContainer } from '../../ai';
import { TaskDescriptionComponent } from '../TaskDescription.component/TaskDescription.component';
import { useResolutions } from '../../common';

export const QuestionsCourseFormComponent = (props) => {
    const {
        task,
        inProgress,
        event,
        question,
        results,
        result,
        handleMouseUp,
        setMenuOpen,
        menuOpen,
        ...other
    } = props;
    const { user } = useContext(FirebaseContext);
    const questionContentRef = useRef((!!question?.question ? question?.question : '') + '\n\n');
    const [questionContent, setQuestionContent] = useState((!!question?.question ? question?.question : '') + '\n\n');
    const onChangeQuestionContent = (content) => {
        setQuestionContent((prev) => {
            const startContent = !prev ? '\n\n' : prev;
            const newContent = startContent + content;
            questionContentRef.current = newContent
            return newContent;
        });
    }
    const scrollToClasses = useRef();
    const { isSMdown } = useResolutions();
    const handleScrollClick = () => { scrollToClasses.current.scrollIntoView(); };
    const instructorSubscriptions = event?.userSubscriptions?.filter(subscription => subscription.role === 'INSTRUCTOR');

    const [assistantOpen, setAssistantOpen] = useState(() => {
        const storedAssistantOpen = window.localStorage.getItem('assistantOpen');
        return storedAssistantOpen !== null ? JSON.parse(storedAssistantOpen) : !isSMdown;
    });
    const handleAssistantOpen = (prev) => {
        const newState = !prev;
        window.localStorage.setItem('assistantOpen', JSON.stringify(newState));
        return newState;
    };

    const panelClassNames = classnames('ai_assistant_panel', {
        ai_assistant_panel_open: assistantOpen,
        ai_assistant_panel_collapsed: !assistantOpen
    });

    const wrapperClassNames = classnames('ai_assistant_panel_content', {
        ai_assistant_panel_content_open: assistantOpen
    });

    const sidebarClassNames = classnames('ai_assistant_panel_wrapper', {
        ai_assistant_panel_wrapper_open: assistantOpen
    });

    useEffect(() => {
        setQuestionContent((!!question?.question ? question?.question : '') + '\n\n');
    }, [question])

    const showAiForStudents = event && !event?.isHideAi;
    return (
        <QuestionFormContext.Provider value={{ getQuestionContent: () => questionContentRef.current }}>
            <div className='question_inner '>
                <div className='content_panel wrapper_center'
                    onMouseUp={handleMouseUp}>
                    <div className="wrapper_progress_fixed flex">
                        {!menuOpen &&
                            <Button className="task-panel-btn"
                                // collapse button 
                                type="text"
                                onClick={() => setMenuOpen(prev => !prev)}>
                                <FontAwesomeIcon icon={faBars} />
                            </Button>
                        }
                        {!!task?.questions?.length &&  event?.isSubscribe &&
                            <TaskProgressContainer
                                event={event}
                                task={task}
                                question={question}
                                result={result}
                                {...other} />
                        }
                    </div>
                    {/* TODO: Need translation */}
                    {(event?.tasks?.length > 20 || task?.questions?.length > 10) && (user?.id === event?.creator?.id) &&
                        <small className="error">Max 20 modules and 10 lessons allowed. Delete extra modules/lessons to generate AI content.</small>}

                    {!question ?
                        <TaskDescriptionComponent
                            {...other}
                            task={task}
                            inProgress={inProgress}
                            showAiForStudents={showAiForStudents}
                            event={event}
                            result={result}
                        />
                        : (
                            <>
                                <QuestionComponent
                                    question={question}
                                    questionContent={questionContent}
                                    task={task}
                                    inProgress={inProgress}
                                    key={question?.id}
                                    showAiForStudents={showAiForStudents}
                                    result={result}
                                    {...other}
                                />
                                {!inProgress && !question?.question &&
                                    <AddNewContentContainer
                                        {...other}
                                        task={task}
                                        question={question}
                                        questionContent={questionContent}
                                        onView={true}
                                        handleChangeQuestionContent={onChangeQuestionContent}
                                        inProgress={inProgress}
                                        event={event}
                                        result={result}
                                    />
                                }
                            </>
                        )}
                    <div ref={scrollToClasses} />

                    {!!results?.length && (
                        <ResultListContainer
                            {...other}
                            taskId={task.id}
                            results={results}
                            task={task}
                            question={question}
                            result={result}
                        />
                    )}
                </div>

                {showAiForStudents && (
                    <div className={sidebarClassNames}>
                        <div className={wrapperClassNames}>
                            <div className="ai_assistant_panel_button_wrapper">
                                <Button
                                    className="ai_assistant_panel_collapse_btn"
                                    type="text"
                                    onClick={() => setAssistantOpen(handleAssistantOpen)}>
                                    <FontAwesomeIcon icon={assistantOpen ? faAngleDoubleRight : faAngleDoubleLeft} />
                                </Button>
                            </div>
                            <div className={panelClassNames}>
                                <div>
                                    <FreeResultFormContainer
                                        {...other}
                                        id={task?.id}
                                        task={task}
                                        result={result}
                                    />
                                </div>

                                <AiAssistantContainer
                                    task={task}
                                    inProgress={inProgress}
                                    question={question}
                                    handleScrollClick={handleScrollClick}
                                    handleMouseUp={handleMouseUp}
                                    instructorId={(instructorSubscriptions && instructorSubscriptions[0]?.studentId)}
                                    result={result}
                                    {...other}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </QuestionFormContext.Provider>
    );
};
