import './scss/index.scss';
import React, { useContext } from 'react';
import QRCode from 'qrcode.react';
import { useIntl } from 'react-intl';

import { FirebaseContext } from '../../../context/firebase/firebaseContext';
import { EmbedContext } from '../../../context/embed';
import { useLocalePrefix } from '../../lang';
import { CertificateLogo } from '../Certificate.component/CertificateLogo';
import { useTaskDripLogic } from '../../task/hooks/useTaskDripLogic';
import { ProgramsSubjectIconComponent } from '../../program/ProgramsSubjectIcon.component/ProgramsSubjectIcon.component';
import { getReadingTime, calculateHours } from '../../common/utils';

export const CertificateBadgeComponent = (props) => {
    const {
        certificate,
        task,
        taskResult,
        gotAchievement,
        ...other
    } = props;
    const { workspace } = useContext(FirebaseContext);
    const { tagsRenew } = useTaskDripLogic(null, task, taskResult, null, false, [null]);

    const { isEmbed } = useContext(EmbedContext) || {};
    const URL = isEmbed || '';
    const [localePrefix] = useLocalePrefix();
    const { formatMessage } = useIntl();
    const reading = task.duration
    ? calculateHours(task.duration, formatMessage)
    : (task?.questions ? getReadingTime([task], formatMessage) : null);

    const date = certificate?.createDate ? new Date(certificate.createDate) : new Date();
    const formattedDayMonth = date ? new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' }).format(date) : '';
    const formattedYear = date ? new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date) : '';

    return (
        <div className="certificate">
            <div className="certificate-content" >
                <div class="border"></div>
                {!certificate?.isAppliedForCurrentUser ? (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div>
                            <p className='certificate-title' style={{ textTransform: 'uppercase' }}>
                                {certificate?.title}
                            </p>
                            <div className='wrapper_content-section'>
                                <h2 className='course-title'>{formatMessage({ id: 'certificate_description_date' })}</h2>
                            </div>

                            {!!tagsRenew?.length &&
                                <div className=' cards '>
                                    {tagsRenew?.map((tag, i) => (
                                        <div className='achievement_card'>
                                            <div className='skills_tag_icon'>
                                                <ProgramsSubjectIconComponent
                                                    source={tag.deficon}
                                                    width={70}
                                                    achievement={{
                                                        ...tag,
                                                        counter: {
                                                            ...tag?.counter,
                                                            done: true
                                                        }
                                                    }}
                                                    colorfull={true}
                                                />
                                                <h4 className='card_achevement__title'>{tag?.title}</h4>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <div style={{ textAlign: 'left' }}>
                                {workspace?.logo &&
                                    <CertificateLogo workspace={workspace} />
                                }
                            </div>

                        </div>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <div>
                            <p className='certificate-title' style={{ textTransform: 'uppercase' }}>
                                {certificate?.title}
                            </p>
                            <h2 className='course-title'>{task?.title?.length > 100 ? task?.title?.substring(0, 100) + '...' : task?.title}</h2>
                            <p className='certificate-description '>
                                {formatMessage({ id: 'certificate_duration' })}: {task?.duration || reading}
                            </p>

                            <div className='wrapper_content-section'>
                                <div className='wrapper_content-section'>
                                    <span className='certificate-description' style={{ textTransform: 'uppercase' }}>
                                        {formatMessage({ id: 'certificate_issued_for' })}
                                    </span>
                                    <h2 className='course-title wrapper_content-section' >
                                        {certificate?.student?.name || "Student name"}
                                    </h2 >

                                    <p className='certificate-description '>
                                        {certificate?.description || formatMessage({ id: 'certificate_description_badge' })}
                                    </p>

                                    {!!tagsRenew?.length &&
                                        <div className=' cards '>
                                            {tagsRenew?.map((tag, i) => (
                                                <div className='achievement_card'>
                                                    <div className='skills_tag_icon'>
                                                        <ProgramsSubjectIconComponent
                                                            source={tag.deficon}
                                                            width={70}
                                                            achievement={{
                                                                ...tag,
                                                                counter: {
                                                                    ...tag?.counter,
                                                                    done: true
                                                                }
                                                            }}
                                                            colorfull={true}
                                                        />
                                                        <h4 className='card_achevement__title'>{tag?.title}</h4>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }}>
                            <div style={{ textAlign: 'left' }}>
                                {workspace &&
                                    <>
                                        <CertificateLogo workspace={workspace} />
                                        <p className='certificate-description '>{workspace?.title || ''}</p>
                                    </>
                                }

                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <h3 className="certificate-sign" style={{ fontWeight: 'bold', fontFamily: 'Caramel, sans-serif' }}>
                                    {workspace?.title || certificate?.creator?.name || "Creator name"}
                                </h3>
                                <p className='certificate-description '>{certificate?.label || formatMessage({ id: 'certificate_label' })}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="certificate-badge_wrapper" >
                <div className="certificate-badge" >
                    <div className="flag"></div>
                    {certificate?.isAppliedForCurrentUser ? (
                        <div className="certificate-date">
                            <p className='certificate-title certificate-description'>{certificate?.userCertificateId || '4245'}</p>
                            <hr className='hr_small' />
                            <p className='certificate-description ' >{formattedDayMonth}</p>
                            <h2 >{formattedYear}</h2>
                            <QRCode
                                value={`https://${window.location.hostname}/${localePrefix}/${URL}user-certificate/${certificate?.userCertificateId}`}
                                size={80}
                                bgColor={"#00000000"}
                                fgColor={"#000000"}
                                level="L"
                            />
                        </div>
                    ) : (
                        <div className="certificate-date">
                            <p>
                                {formatMessage({ id: 'form_is_steps' })}: {tagsRenew[0]?.counter?.done} / {tagsRenew[0]?.counter?.all} {formatMessage({ id: 'lessons' })}
                            </p>
                        </div>
                    )}
                </div>
                <img
                    className={certificate?.active ? 'certificate_img certificate_img_active' : 'certificate_img'}
                    alt='subject logo'
                    src={certificate?.icon || 'https://cdn.unschooler.me/web_app/achievement/cert/cert_1.png'}
                />
            </div>
        </div>
    );
};